import React from "react";
import TextPage from "../components/TextPage";

function ImpressumPage(){
    return (
        <TextPage heading="Impressum" slim>
            <div className="card">
                <p>
                    <strong>Legal Software UG (haftungsbeschränkt)</strong><br/>
                    Feilitzschstr. 7<br/>
                    80802 München<br/>
                </p>
                <p>
                    Vertreten durch:<br/>
                    Maximilian Pschiebel<br/>
                </p>
                <p>
                    Umsatzsteuer-Identifikationsnummer<br/>
                    gemäß §27a Umsatzsteuergesetz: DE347168681<br/>
                </p>
                <p>
                    Sitz der Gesellschaft:<br/>
                    München<br/>
                </p>
                <p>
                    Registergericht:<br/>
                    Amtsgericht München  HRB 268624<br/>
                </p>
                <p>
                    Kontakt:<br/>
                    E-Mail: info@legalsoftware.io<br/>
                </p>
                <p>
                    Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:<br/>
                    Tom Schamberger<br/>
                    Birketweg 41<br/>
                    80639 München<br/>
                </p>
            </div>
        </TextPage>
)
}

export default ImpressumPage